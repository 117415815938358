import React, { useEffect, useState } from "react"
import Layout from "./layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import { Editor } from "@tinymce/tinymce-react"
import calendar from "../images/calendar.svg"
import UploadFile from "../images/upload-file.svg"
import {
  translate,
  getUserLanguage,
  AUTH_TOKEN_KEY,
  URL_GETALL_ADVANTAGES,
  ALERT_MSG_ERROR,
  URL_IS_AUTH,
} from "../utils"
import $ from "jquery"
const Advantage_Cop = ({ typeOfCoponent = "Nl", changeBackground,Id }) => {
  /************* Const Variables ************** */

  /************** Validation Title***************** */
  const [titleValidation, setTitleValidation] = useState(false)
  /************** Validation SubTitle***************** */
  const [SubTitleval, setSubTitleval] = useState(false)

  /***************** Validation Short description ********************* */
  const [ShortdescriptionValidation, setShortdescriptionValidation] = useState(false) 
  /***************** Validation description ********************* */
  const [StepdescriptionValidation, setStepdescriptionValidation] = useState(false)



  /**************************************************** */
  const [placeholderDescription, setplaceholderDescription] = useState("")
  const [placeholderReduction, setplaceholderReduction] = useState("")
  const [description, setDescription] = useState("")

  const [placeholderDownLink, setplaceholderDownLink] = useState("")
  useEffect(() => {
    setplaceholderDescription("_" + translate("description")) //
    setplaceholderReduction(translate("-20% your first yearly subscription"))

    setplaceholderDownLink(
      "_" +
        translate(
          "thank you for providing us a download link (WeTransfer, MyAirBridge,…)"
        )
    )
  }, [])

  /******************************* Keys FR OR NL *************/
  let Keytitle = `title${typeOfCoponent === "FR" ? "FR" : ""}`
  let keysubTitle = `subTitle${typeOfCoponent === "FR" ? "FR" : ""}`
  let keyDescription = `Description${typeOfCoponent === "FR" ? "FR" : ""}`
  let keyshortDescription = `shortDescription${
    typeOfCoponent === "FR" ? "FR" : ""
  }`
  let keystepsdescription = `stepsdescription${
    typeOfCoponent === "FR" ? "FR" : ""
  }`
  let keyfieldsOfActivity = `fieldsOfActivity${
    typeOfCoponent === "FR" ? "FR" : ""
  }`
  let keytags = `tags${typeOfCoponent === "FR" ? "FR" : ""}`
  let keyvideoDownLink = `videoDownLink${typeOfCoponent === "FR" ? "FR" : ""}`
  let keys2500x500 = `s2500x500${typeOfCoponent === "FR" ? "FR" : ""}`
  let keyss420x700 = `s420x700${typeOfCoponent === "FR" ? "FR" : ""}`

  const editorSettings = {
    branding: false,
    height: 500,
    name: keyDescription,
    menubar: false,
    placeholder: placeholderDescription,
    resize: false,
    statusbar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor textcolor",
      "searchreplace visualblocks code fullscreen",
      "media table paste code",
    ],
    toolbar:
      "formatselect | image link media | bold italic forecolor backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | code",
  }
  return (
    <Col id={Id}>
      <Form.Group>
        <Form.Label>
          <strong>{translate("title of the advice")}</strong>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={placeholderReduction}
          name={Keytitle}
          onChange={e =>
            setTitleValidation(
               e.target.value.trim().length === 0
            )
          }
          isInvalid={titleValidation}
        />
        <Form.Control.Feedback type="invalid">
          {translate("le titre doit etre remplie")}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          <strong>{translate("Subtitle  of the advice")}</strong>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={"subTitle"}
          name={keysubTitle}
          onChange={e =>
            setSubTitleval(
             e.target.value.trim().length === 0
            )
          }
          isInvalid={SubTitleval}
        />
        <Form.Control.Feedback type="invalid">
          {translate("le subtitle doit etre remplie")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <strong>{translate("short Description")}</strong>
        </Form.Label>
        <Form.Control
          as="textarea"
          placeholder={"short Description"}
          name={keyshortDescription}
          onChange={e =>
            setShortdescriptionValidation(
               e.target.value.trim().length === 0
            )
          }
          isInvalid={ShortdescriptionValidation}
        />
        <Form.Control.Feedback type="invalid">
          {translate("la petite description  doit etre remplie")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          <strong>{translate("steps Description")}</strong>
        </Form.Label>
        <Form.Control
          as="textarea"
          placeholder={"steps Description"}
          name={keystepsdescription}
          onChange={e =>
            setStepdescriptionValidation(
               e.target.value.trim().length === 0
            )
          }
          isInvalid={StepdescriptionValidation}
        />
           <Form.Control.Feedback type="invalid">
          {translate("le Step doit etre remplie")}
        </Form.Control.Feedback>
      </Form.Group>
   
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>
          <strong> {translate("description")}</strong>
        </Form.Label>
        <Editor
          apiKey="apya3p5m6gnmb567a82qd5y4t221zeihzbdsw9mml629y2s8"
          placeholder={placeholderDescription}
          init={editorSettings}
            onChange={e => {
              setDescription(e.target.getContent())
            }}
        />
        <input type="hidden" value={`${description}`} name={keyDescription}/>
      </Form.Group>
      <Form.Group  className="hideme">
        <Form.Label>
          <strong>{translate("fields Of Activity")}</strong>
        </Form.Label>
        <Form.Control as="select" name={keyfieldsOfActivity}>
          <option value={translate("services aux entreprises")}>
            {translate("services aux entreprises")}
          </option>
          <option value={translate("services immobilier")}>
            {translate("services immobilier")}
          </option>
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>
          <strong>{translate("tags")}</strong>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={"tags as('business','industry')"}
          name={keytags}
        />
      </Form.Group>

      <p className="text-secondary">
        {" "}
        <strong>{translate("do you want to add a video") + "?"}</strong>{" "} 
      </p>
      <InputGroup className="mb-3 form-style-two">
        {/* <InputGroup.Prepend>
                      <InputGroup.Checkbox aria-label="" />
                    </InputGroup.Prepend> */}
        <FormControl
          aria-label=""
          placeholder={placeholderDownLink}
          name={keyvideoDownLink}
        />
      </InputGroup>

      <Row className="pt-4">
        <Col xl="12">
          <div className="file-upload-wrap red-alpha-gradient">
            <span className="top-fixed-tag red-gradient">2500 x 500px</span>
            <div className="mb-3">
              <Form.File id={`formcheck-api-regular1-${typeOfCoponent}`}>
                <Form.File.Label>
                  <img
                    width={69.22}
                    height={61.3}
                    className="img-fluid"
                    src={UploadFile}
                    alt=""
                  />
                  {translate("add header")}
                </Form.File.Label>
                <Form.File.Input
                  name={keys2500x500}
                  onChange={changeBackground}
                />
              </Form.File>
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className="file-upload-wrap red-alpha-gradient">
            <span className="top-fixed-tag red-gradient">420 x 700px</span>
            <div className="mb-3">
              <Form.File id={`formcheck-api-regular9-${typeOfCoponent}`}>
                <Form.File.Label style={{ height: "700px" }}>
                  <img
                    width={69.22}
                    height={61.3}
                    className="img-fluid"
                    src={UploadFile}
                    alt=""
                  />
                  {translate("add image")}
                </Form.File.Label>
                <Form.File.Input
                  name={keyss420x700}
                  onChange={changeBackground}
                />
              </Form.File>
              
              <input type="hidden" value=   {  !titleValidation && !SubTitleval && !ShortdescriptionValidation && !StepdescriptionValidation ? "T":"N" } name={`${typeOfCoponent}`}/>
           
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  )
}

export default Advantage_Cop
