import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import { Editor } from "@tinymce/tinymce-react"
import calendar from "../images/calendar.svg"
import UploadFile from "../images/upload-file.svg"
import {
  translate,
  getUserLanguage,
  AUTH_TOKEN_KEY,
  URL_GETALL_ADVANTAGES,
  ALERT_MSG_ERROR,
  URL_IS_AUTH,
} from "../utils"
import $ from "jquery"
import axios from "axios"

import ModalAlert from "../components/modalAlert"
import Advantage_Cop from "../components/Advantage_Cop"

const Addadvantage = () => {
  const lang = getUserLanguage()

  const [validate, setValidate] = useState(false)
  const [visibleBtn, setVisibleBtn] = useState(true)
  const [TextButton, setTextButton] = useState(translate("Suivant"))

  const [showAlert, setShowAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")

  /****************** Send Event Data************************** */
  const [placeholderDateBegin, setplaceholderDateBegin] = useState("")
  const [placeholderDateEnd, setplaceholderDateEnd] = useState("")
  const changeBackground = function (e) {
    console.table(window.URL.createObjectURL(e.target.files[0]))
    let url = window.URL.createObjectURL(e.target.files[0])
    $(e.target.parentElement.children[0]).css("visibility", "hidden")
    $(e.target.parentElement.parentElement.parentElement).css(
      "background",
      `url('${url}') no-repeat 100% center`
    )
  }

  const submitHandler = async () => {
    // if (!validate) {
    //   return false
    // }
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }

    let form = new FormData(document.querySelector("form"))
    // form.append("descriptionFR", descriptionFR)
    // form.append("description", description)

    ///cherif
    axios
      .post(URL_GETALL_ADVANTAGES, form, header)
      .then(res => {
        if (res.data) {
          console.log(res.data)
        }
        console.log("update succesfull")
        //  window.location.reload();
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data)
          setShowAlert(true)
          setAlertTitle(ALERT_MSG_ERROR)
          setAlertMessage(err.response.data.message)
        }
        console.log(err)
        //  setTimeout(() => window.location.reload(),3000)
      })
    ///
  }

  const toggleDiv = async () => {
    $("#FRAdvantage").slideToggle()
    $("#NlAdvantage").slideToggle()

    await setVisibleBtn(!visibleBtn)
    console.log("Hey", visibleBtn)
    await setTextButton(
      !visibleBtn ? translate("Suivant") : translate("Retour")
    )
  }

  useEffect(() => {

    if(localStorage.getItem(AUTH_TOKEN_KEY))
            auth();
            else{
              window.location.href = "/login"
            }
            
    $(document).ready(function () {
      $("#FRAdvantage").toggle()
      $("#step2").hide("fast")
      $(".step2").hide()
    })
    setplaceholderDateBegin(translate("pick up begin op advantage date"))
    setplaceholderDateEnd(translate("pick up end op advantage date"))
    auth()
  }, [])
  const auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_IS_AUTH, data)
      .then(res => {
        if (res.data) {
          console.log("Auth")
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else console.log(err)
        window.location.href = "/login"
      })
  }

  return (
    <Layout>
      <div className="formWrapper label-font-wrap">
        <Container>
          <Row className="justify-content-center">
            <Col md="12" lg="6" xl="6">
              <div className="text-left">
                <h2 className="mb-3">Add an advantage</h2>

 
                
                <Form encType="multipart/form-data">
                  <Col className="step1">
                    <Advantage_Cop
                      typeOfCoponent="FR"
                      changeBackground={changeBackground}
                      Id="FRAdvantage"
                    />
                    <Advantage_Cop
                      typeOfCoponent="Nl"
                      changeBackground={changeBackground}
                      Id="NlAdvantage"
                    />
                    <Col
                      xl="6"
                      lg="6"
                      sm="6"
                      md="6"
                      className="d-flex justify-content-center"
                    >
                      <Button
                        variant={visibleBtn ? "primary" : "secondary"}
                        className=" mb-5 mr-4 "
                        onClick={toggleDiv}
                      >
                        {TextButton}
                      </Button>

                      <Button
                        variant="success"
                        className={` mb-5  ${!visibleBtn ? "" : "d-none"}`}
                        onClick={() => {
                          $("#step2,.step2").show()
                          $(".step1").hide()
                        }}
                      >
                        {translate("Terminer")}
                      </Button>
                    </Col>
                  
                   
                  </Col>
                  <Button
                      variant="success"
                      className={` mb-5 step2 `}
                      onClick={() => {
                        $(".step1").slideToggle()
                        $(".step2").hide()
                        $("#step2").hide("fast")
                      }}
                    >
                      {translate("Cancel")}
                    </Button>
                  <hr />
                  <Col xl="12" lg="12" sm="12" md="12" id="step2">
                    <Row>
                      <Col xl="6">
                        <div className="file-upload-wrap red-alpha-gradient">
                          <span className="top-fixed-tag red-gradient">
                            420 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id="formcheck-api-regular2">
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              {/* <input type="file" className="upload" /> */}
                              <Form.File.Input
                                name="s420x350[]"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="file-upload-wrap red-alpha-gradient">
                          <span className="top-fixed-tag red-gradient">
                            420 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id="formcheck-api-regular3">
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s420x350[]"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="file-upload-wrap red-alpha-gradient">
                          <span className="top-fixed-tag red-gradient">
                            420 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id="formcheck-api-regular4">
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s420x350[]"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="file-upload-wrap red-alpha-gradient">
                          <span className="top-fixed-tag red-gradient">
                            420 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id="formcheck-api-regular5">
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s420x350[]"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="file-upload-wrap red-alpha-gradient">
                          <span className="top-fixed-tag red-gradient">
                            866 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id="formcheck-api-regular6">
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s866x350[]"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                      <Col xl="12">
                        <div className="file-upload-wrap red-alpha-gradient">
                          <span className="top-fixed-tag red-gradient">
                            866 x 350px
                          </span>
                          <div className="mb-3">
                            <Form.File id="formcheck-api-regular7">
                              <Form.File.Label>
                                <img
                                  width={69.22}
                                  height={61.3}
                                  className="img-fluid"
                                  src={UploadFile}
                                  alt=""
                                />
                                {translate("add image")}
                              </Form.File.Label>
                              <Form.File.Input
                                name="s866x350[]"
                                onChange={changeBackground}
                              />
                            </Form.File>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Col>
                      <h1 className="gray-poppins mt-4 mb-3">
                        {translate("add feed banners")}
                      </h1>
                    </Col>
                    <Col xl="12">
                      <div className="file-upload-wrap red-alpha-gradient">
                        <span className="top-fixed-tag red-gradient">
                          866 x 250px
                        </span>
                        <div className="mb-3">
                          <Form.File id="formcheck-api-regular8">
                            <Form.File.Label style={{ height: "250px" }}>
                              <img
                                width={69.22}
                                height={61.3}
                                className="img-fluid"
                                src={UploadFile}
                                alt=""
                              />
                              {translate("add image")}
                            </Form.File.Label>
                            <Form.File.Input
                              name="s866x250"
                              onChange={changeBackground}
                            />
                          </Form.File>
                        </div>
                      </div>
                    </Col>

                    <p className="text-secondary">
                      {" "}
                      <strong>{translate("dates of advantage")}</strong>
                      {"(" + translate("unlimited by default") + ")"}{" "}
                    </p>
                    <Row>
                      <Col lg="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={16}
                                height={8}
                                className="img-fluid"
                                src={calendar}
                                alt=""
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder={placeholderDateBegin}
                            aria-label=""
                            aria-describedby="basic-addon1"
                            name="validityDateStart"
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={16}
                                height={8}
                                className="img-fluid"
                                src={calendar}
                                alt=""
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder={placeholderDateEnd}
                            aria-label=""
                            aria-describedby="basic-addon1"
                            name="validityDateEnd"
                          />
                        </InputGroup>
                      </Col>
                    </Row>

                    <Form.Group
                      controlId="formBasicCheckbox"
                      className="d-flex justify-content-center  mb-4"
                    >
                      <Form.Check
                        className="text-certer"
                        type="checkbox"
                        label={translate(
                          "I am sure all the information is correct before save it and make it validate by the Enter Business Team"
                        )}
                        onChange={e => {
                          setValidate(e.target.checked)
                        }}
                      />
                    </Form.Group>

                    <Button
                      variant="danger"
                      type="button"
                      onClick={submitHandler}
                      disabled={!validate}
                    >
                      Save
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        {showAlert ? (
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => (setShowAlert (false))}
          />
        ) : null}
        {/* modal */}
      </div>
    </Layout>
  )
}

export default Addadvantage
